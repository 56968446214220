









import { Component, Prop, Vue } from "vue-property-decorator";
import ButtonOption from "./CustomButtonOption.vue";

@Component({
  components: { ButtonOption },
})
export default class SidebarButtonOption extends Vue {
  @Prop()
  value!: Record<string, string>;
}
