


















































import { Component, Prop, Vue } from "vue-property-decorator";
import { resetNodeSelection } from "@/supportMethods";

interface ValueData {
  title: string;
  additionalMaterial: Array<ContentData>;
}

interface ContentData {
  id: number;
  name: string;
  url: string;
}

@Component
export default class SidebarAdditionalMaterial extends Vue {
  @Prop()
  value!: ValueData;
  additionalMaterialName = "";
  additionalMaterialPath = "";

  addNewAdditionalMaterial(): void {
    const content = this.value.additionalMaterial;
    let nextAdditionalMaterialId;
    // set id based on the last item, otherwise set id as 1
    if (content.length > 0) {
      nextAdditionalMaterialId = content[content.length - 1].id + 1;
    } else {
      nextAdditionalMaterialId = 1;
    }
    if (typeof this.value === "object") {
      this.value.additionalMaterial.push({
        id: nextAdditionalMaterialId,
        name: this.additionalMaterialName,
        url: this.additionalMaterialPath,
      });
    }
    this.additionalMaterialName = "";
    this.additionalMaterialPath = "";
  }
  removeAdditionalMaterial(index: number): void {
    const content = this.value.additionalMaterial;
    content.splice(index, 1);
  }
  resetNodeSelection() {
    resetNodeSelection();
  }
}
