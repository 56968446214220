













import { Component, Prop, Vue } from "vue-property-decorator";
import { InputOption } from "@baklavajs/plugin-options-vue";

@Component({
  components: { InputOption },
})
export default class SidebarEndOption extends Vue {
  @Prop()
  value!: Record<string, string>;
}
