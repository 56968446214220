













import { Component, Prop, Vue } from "vue-property-decorator";
import { resetNodeSelection } from "@/supportMethods";

@Component
export default class InputOption extends Vue {
  @Prop({ type: String, default: "" })
  value!: string;

  @Prop({ type: String })
  name!: string;

  get listeners(): any {
    return {
      ...this.$listeners,
      input: (ev: any) => this.$emit("input", ev.target.value),
    };
  }

  resetNodeSelection() {
    resetNodeSelection();
  }

  // test() {
  //   alert("bla");
  //   // this.$root.$editor.
  //   const editor = this.$root.$children[0].$children[0].$refs.editor;
  //   (editor as VueComponent).unselectAllNodes();
  //   this.$root.$children[0].$children[0].$refs.editor.unselectAllNodes();
  //   this.$root.$refs.editor;
  // }
}
