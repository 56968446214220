












import { Component, Prop, Vue } from "vue-property-decorator";
import { resetNodeSelection } from "@/supportMethods";

@Component
export default class ButtonOption extends Vue {
  @Prop({ type: String })
  name!: string;

  resetNodeSelection() {
    resetNodeSelection();
  }
}
