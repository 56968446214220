






















































import { Component, Prop, Vue } from "vue-property-decorator";
import { InputOption } from "@baklavajs/plugin-options-vue";
import { resetNodeSelection } from "@/supportMethods";

interface ValueData {
  title: string;
  additionalContent: Array<ContentData>;
}

interface ContentData {
  id: number;
  name: string;
  url: string;
}

@Component({
  components: { InputOption },
})
export default class SidebarSceneDataOption extends Vue {
  @Prop()
  value!: ValueData;
  externalDocName = "";
  externalDocUrl = "";
  addNewExternalDoc(): void {
    const content = this.value.additionalContent;
    let nextExternalDocId;
    // set id based on the last item, otherwise set id as 1
    if (content.length > 0) {
      nextExternalDocId = content[content.length - 1].id + 1;
    } else {
      nextExternalDocId = 1;
    }
    if (typeof this.value === "object") {
      this.value.additionalContent.push({
        id: nextExternalDocId,
        name: this.externalDocName,
        url: this.externalDocUrl,
      });
    }
    this.externalDocName = "";
    this.externalDocUrl = "";
  }
  removeExternalDoc(index: number): void {
    const content = this.value.additionalContent;
    content.splice(index, 1);
  }
  resetNodeSelection() {
    resetNodeSelection();
  }
}
