import { NodeBuilder } from "@baklavajs/core";

export default new NodeBuilder("Decision point", {
  twoColumn: true,
  width: 300,
})
  .addInputInterface("Input", undefined, null, { type: "flow" })
  .addOutputInterface("Choice 1", { type: "decision" })
  .addOutputInterface("Choice 2", { type: "decision" })
  .addOutputInterface("Choice 3", { type: "decision" })
  .addOutputInterface("Choice 4", { type: "decision" })
  .addOutputInterface("Choice 5", { type: "decision" })
  .addOption(
    "Dialogue",
    "DialogueOption",
    () => ({
      title: "Edit text",
      name: "decision point",
      content: "Placeholder text.",
    }),
    "SidebarSceneDataOption"
  )
  .addOption(
    "Choices",
    "CustomButtonOption",
    () => ({}),
    "SidebarChoicesOption"
  )
  .addOption(
    "Additional material",
    "SidebarButtonOption",
    () => ({
      title: "Additional material",
      additionalMaterial: [],
    }),
    "SidebarAdditionalMaterial"
  )
  .addOption("Play audio", "CheckboxOption")
  .addOption("Audio URL", "CustomInputOption")
  .build();
