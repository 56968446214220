












import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ImageOption extends Vue {
  @Prop({ type: String, default: "" })
  value!: string;
}
