










import { Component, Prop, Vue } from "vue-property-decorator";
import ButtonOption from "./CustomButtonOption.vue";

@Component({
  components: { ButtonOption },
  filters: {
    truncate: function (value: any, length: number) {
      if (!value) return "";
      // get total number of words in content
      const currentLength = value.split(" ").length;
      value = value.toString();
      // check if the current content word-length is higher than the defined length
      if (currentLength > length) {
        // remove words after the defined length and add [...] at the end
        return value.split(" ").splice(0, length).join(" ") + " [...]";
      } else {
        return value;
      }
    },
  },
})
export default class DialogueOption extends Vue {
  @Prop()
  value!: Record<string, string>;
}
