




















































import { Component, Prop, Vue } from "vue-property-decorator";
import { resetNodeSelection } from "@/supportMethods";

@Component
export default class SidebarChoicesOption extends Vue {
  @Prop()
  value!: Record<string, string>;

  resetNodeSelection() {
    resetNodeSelection();
  }
}
