























import { Component } from "vue-property-decorator";
import Arrow from "./Arrow.vue";
// import { BaseNumericOption } from "@baklavajs/plugin-options-vue/dist/baklavajs-plugin-options-vue/src/BaseNumericOption";
import { BaseNumericOption } from "./BaseNumericOption";

@Component({
  components: {
    "i-arrow": Arrow,
  },
})
export default class IntegerOption extends BaseNumericOption {
  get v() {
    if (typeof this.value === "string") {
      return parseInt(this.value, 10);
    } else if (typeof this.value === "number") {
      return Math.floor(this.value);
    } else {
      return 0;
    }
  }

  get stringRepresentation() {
    const s = this.v.toString();
    return s.length > this.MAX_STRING_LENGTH
      ? this.v.toExponential(this.MAX_STRING_LENGTH - 5)
      : s;
  }

  increment() {
    this.setValue(this.v + 1);
  }

  decrement() {
    this.setValue(this.v - 1);
  }

  leaveEditMode() {
    const v = parseInt(this.tempValue, 10);
    if (!this.validate(v)) {
      this.invalid = true;
    } else {
      this.$emit("input", v);
      this.editMode = false;
    }
  }
}
