







































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CharactersOption extends Vue {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: String })
  name!: string;
}
