import store from "@/store";
import { BaklavaVuePlugin } from "@baklavajs/plugin-renderer-vue";
import "@baklavajs/plugin-renderer-vue/dist/styles.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faClone,
  faDownload,
  faFile,
  faFolderOpen,
  faGraduationCap,
  faPlus,
  faRotateLeft,
  faRotateRight,
  faTrash,
  faTv,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "shepherd.js/dist/css/shepherd.css";
import Vue from "vue";
import App from "./App.vue";
import "./style.css";

library.add(
  faFile,
  faFolderOpen,
  faDownload,
  faRotateLeft,
  faRotateRight,
  faPlus,
  faTv,
  faClone,
  faTrash,
  faGraduationCap
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(BaklavaVuePlugin);

new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");
