





























import { Component, Prop, Vue } from "vue-property-decorator";
import { CheckboxOption } from "@baklavajs/plugin-options-vue";
import store from "@/store";

@Component({
  components: { CheckboxOption },
})
export default class SidebarSceneSettings extends Vue {
  @Prop()
  value!: unknown;

  get showIfDecisionWasCorrect() {
    return store.state.showIfDecisionWasCorrect;
  }

  set showIfDecisionWasCorrect(bool) {
    store.commit("updateShowIfDecisionWasCorrect", bool);
  }

  get allowIncorrectDecision() {
    return store.state.allowIncorrectDecision;
  }

  set allowIncorrectDecision(bool) {
    store.commit("updateAllowIncorrectDecision", bool);
  }

  get useTTS() {
    return store.state.useTTS;
  }

  set useTTS(bool) {
    store.commit("updateUseTTS", bool);
  }
}
