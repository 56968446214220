











import { Component, Prop, Vue } from "vue-property-decorator";
import Tiptap from "../Tiptap.vue";

@Component({
  components: { Tiptap },
})
export default class SidebarSceneDataOption extends Vue {
  @Prop()
  value!: Record<string, string>;
}
