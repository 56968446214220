






import { Component, Vue } from "vue-property-decorator";
import Baklava from "@/components/baklava.vue";

@Component({
  components: {
    Baklava,
  },
})
export default class App extends Vue {
  created() {
    window.addEventListener("beforeunload", this.reloadWarning);
  }
  reloadWarning(event: Event): void {
    event.preventDefault();
  }
}
